import { Link } from "react-router-dom"

import logo from '../assets/images/logo.svg';
import qrCodeImg from '../assets/images/qr_group.png';
import stepImg01PC from '../assets/images/group_img01_pc.jpg';
import stepImg02PC from '../assets/images/group_img02_pc.jpg';
import stepImg01SP from '../assets/images/group_img01_sp.jpg';
import stepImg02SP from '../assets/images/group_img02_sp.jpg';

import s from '../assets/scss/pages/SubPage.module.scss';


export default function GroupPage() {
  return (
    <div className={s["page-group"]}>
      <div className={s["logo"]}>
        <div className="container">
          <Link to="/"><img src={logo} alt="Zukan Museum Niigata" /></Link>
        </div>
      </div>

      <div className={s["intro"]}>
        <div className="container">
          <p className={s["intro__lead"]}>
            あなたのスマートフォンで、ずかんミュージアムのいきものを<br />
            正しく記録できるか確認できます。
          </p>

          <div className={s["block"]}>
            <p className={s["intro__title"]}>＜用意するもの＞</p>
            <p className={s["intro__text"]}>スマートフォン(タブレット)を２台</p>
            <small className={s["intro__notice"]}>※確認用画像を開く際は、PCでも問題ありません。</small>
          </div>
        </div>
      </div>

      <div className={s["main"]}>
        <div className={`container ${s["main__container"]}`}>
          <div className={s["block"]}>
            <div className={s["block__head"]}><p>確認の手順</p></div>
            <div className={s["block__main"]}>
              <div className={s["steps"]}>
                <div className={s["steps__item"]}>
                  <p className={s["steps__title"]}><span>1</span>カメラ機能が起動するか確認！</p>
                  <p className={s["steps__text"]}>体験で使用予定のスマートフォン(タブレット)で確認してください。</p>
                  <div className={s["steps__qr"]}>
                    <figure><img src={qrCodeImg} alt="" /></figure>
                    <span>
                      こちらの2次元コードを<br />
                      スマートフォンのカメラで<br />
                      読み込んでください
                    </span>
                  </div>

                  <div className={s["steps__btnwrap"]}>
                    <div className={s["steps__btn"]}>
                      <Link to="/group/camera" target="_blank"><span>クリックして、カメラ機能を確認</span></Link>
                    </div>
                    <p className={s["steps__text"]}>
                      こちらのボタンをクリックして<br />
                      カメラを起動してみてください
                    </p>
                  </div>
                </div>

                <div className={s["steps__item"]}>
                  <p className={s["steps__title"]}><span>2</span>確認用の画像を開く！</p>
                  <p className={s["steps__text"]}>もう1台のスマートフォン(タブレット)で<br className="sp-hide"/>下の画像を開いてください。</p>
                  <div className={s["steps__image"]}>
                    <a href={stepImg01SP} target="_blank">
                      <img className="sp-hide" src={stepImg01PC} alt="" />
                      <img className="sp-show" src={stepImg01SP} alt="" />
                    </a>
                  </div>
                  <p className={s["steps__text"]}>画像をタップして全画面表示してください</p>
                </div>

                <div className={s["steps__item"]}>
                  <p className={s["steps__title"]}><span>3</span>カメラを確認用画像に向けてチェック！</p>
                  <p className={s["steps__text"]}>①で起動したカメラを、②で開いた画像に向けてみてください。</p>
                  <div className={s["steps__image"]}>
                    <img className="sp-hide" src={stepImg02PC} alt="" />
                    <img className="sp-show" src={stepImg02SP} alt="" />
                  </div>
                  <p className={s["steps__text"]}>このように表示されると成功です</p>
                </div>
              </div>

              <div className={s["block__notice"]}>
                <small>
                  ※カメラが起動しない場合や、起動に時間がかかる場合は、施設で端末をレンタルしてください。
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}