import { AnimatePresence, motion } from 'framer-motion';

import iconStone from '../assets/images/icon-record-stone.png';
import iconClose from '../assets/images/icon-close.svg';
import bgBlue from '../assets/images/bg-blue.jpg';

import s from '../assets/scss/components/AnimalDetail.module.scss';

export default function AnimalDetailModal({ imageUrl, desc, onClose }) {
  const handleCloseClick = e => {
    e.preventDefault();
    onClose && onClose();
  }

  return (
    <motion.div
      className={s["modal"]}
      style={{ backgroundImage: `url(${bgBlue})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}>
      <div className={s["container"]}>
        <div className={s["btn-close"]}>
          <a href="#" onClick={e => handleCloseClick(e)}>
            <img src={iconClose} alt="" />
          </a>
        </div>

        <div className={s["detail"]}>
          {imageUrl && (
            <div className={s["detail__img"]}><img src={imageUrl} alt="" /></div>
          )}

          {desc !== "" && (
            <div className={s["detail__desc"]}>
              <p>{desc}</p>
            </div>
          )}

          <div className={s["detail__bottom"]}>
            <div className={s["detail__bottom-title"]}>
              <img src={iconStone} alt="" />
              <p>ずかんミュージアム<br />にいがたにいこう！</p>
            </div>
            <div className={s["detail__bottom-text"]}>
              <p>
                いきものをたくさんきろくすると<br />
                オリジナルステッカーをプレゼント！
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}