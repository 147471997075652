import { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import * as tf from '@tensorflow/tfjs';
import { loadGraphModel } from '@tensorflow/tfjs-converter';
import { motion } from 'framer-motion';

import s from '../assets/scss/pages/CameraPage.module.scss';

import iconClose from '../assets/images/icon-close.svg';
import iconStone from '../assets/images/icon-record-stone.png';

const threshold = 0.8;

async function loadModel() {
  const model = await loadGraphModel(`${process.env.PUBLIC_URL}/models/zkn-efficientdet/model.json`);
  return model;
}

const classesDir = {
  1: { name: 'panda', id: 1, },
  2: { name: 'elephant', id: 2, },
  3: { name: 'gnu', id: 3, },
  4: { name: 'moose', id: 4, },
  5: { name: 'marker', id: 5, },
  6: { name: 'bear', id: 6, },
}

export default function IndividualCameraPage() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [videoSize, setVideoSize] = useState({ x: 390, y: 844 });
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    let currentStream = null;
    let animLoop = null;
    let model;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const modelPromise = loadModel();

      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: { facingMode: "environment" }
        })
        .then(stream => {
          currentStream = stream;
          window.stream = stream;
          videoRef.current.srcObject = stream;

          return new Promise((resolve, reject) => {
            videoRef.current.onloadedmetadata = () => {
              console.log('1. Camera stream Loaded');
              resolve();
            };
          });
        });

      Promise.all([modelPromise, webCamPromise])
        .then(values => {
          model = values[0];
          animLoop = requestAnimationFrame(() => detectFrame());
          console.log('2. Model Loaded')
          console.log(videoRef.current);
        })
        .catch(error => {
          console.error(error);
        });
    }

    function detectFrame() {
      if (!videoRef.current || !model) return;
      tf.engine().startScope();
      model.executeAsync(processInput(videoRef.current)).then(predictions => {
        renderPredictions(predictions, videoRef.current);

        animLoop = requestAnimationFrame(() => detectFrame());

        console.log('3. Animation loop running...');
        setIsModalVisible(true);

        tf.engine().endScope();
      });
    };

    return () => {
      cancelAnimationFrame(animLoop);

      if (currentStream) {
        const tracks = currentStream.getTracks();
        tracks.forEach(track => track.stop());
        currentStream = null;
        window.stream = null;
        // videoRef.current.srcObject = null;
      }
    };
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setVideoSize({ x: parseInt(width), y: parseInt(height) });
      }
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function processInput(video_frame) {
    const tfimg = tf.browser.fromPixels(video_frame).toInt();
    const expandedimg = tfimg.transpose([0, 1, 2]).expandDims();
    return expandedimg;
  };

  function buildDetectedObjects(scores, boxes, classes, classesDir) {
    const detectedObjects = []
    // var videoFrame = document.getElementById('frame');
    const videoFrame = canvasRef.current;

    const th = threshold;
    scores[0].forEach((score, i) => {
      if (classes && classes[i] && score > th) {
        const bbox = [];
        const minY = boxes[0][i][0] * videoFrame.offsetHeight;
        const minX = boxes[0][i][1] * videoFrame.offsetWidth;
        const maxY = boxes[0][i][2] * videoFrame.offsetHeight;
        const maxX = boxes[0][i][3] * videoFrame.offsetWidth;
        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;


        detectedObjects.push({
          class: Math.round(classes[i]),
          label: classesDir[Math.round(classes[i])].name,
          score: score.toFixed(4),
          bbox: bbox
        });
      }
    })

    return detectedObjects;
  }

  function renderPredictions(predictions) {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    let boxes, scores, classes;
    predictions.forEach((prediction, i) => {  // HACK: 毎フレームやらなくても初期化時に1度でよい
      const arr = prediction.arraySync();
      const pr = arr[0];

      if (Array.isArray(pr) && pr.length === 100) {
        if (Array.isArray(pr[0])) {
          if (pr[0].length === 4) { // WARNING: クラス数が4の時、誤って他のインデックスを指定する可能性がある
            boxes = prediction.arraySync();
          }
        } else if (Number.isInteger(pr[0])) {
          let isClass = true;
          for (let j = 0; j < pr.length; j++) {
            if (pr[j] > Object.keys(classesDir).length) {
              isClass = false;
              break;
            }
          }
          if (isClass) {
            classes = prediction.dataSync();
          }
        } else {
          scores = prediction.arraySync();
        }
      }
    })

    const detections = buildDetectedObjects(scores, boxes, classes, classesDir);

    // Draw frame
    // detections.forEach(item => {
    //   const x = item['bbox'][0];
    //   const y = item['bbox'][1];
    //   const width = item['bbox'][2];
    //   const height = item['bbox'][3];

    //   // Draw the bounding box.
    //   ctx.strokeStyle = "#ffffff";
    //   ctx.lineWidth = 4;
    //   ctx.strokeRect(x, y, width, height);

    //   // Draw the label background.
    //   ctx.fillStyle = "#ffffff";
    //   const label = item["label"] + " " + (100 * item["score"]).toFixed(2) + "%";
    //   const textWidth = ctx.measureText(label).width;
    //   const textHeight = parseInt(font, 10); // base 10
    //   ctx.fillRect(x, y, textWidth + 4, textHeight + 4);

    //   // Draw label text.
    //   ctx.fillStyle = "#000000";
    //   ctx.fillText(label, x, y);
    // });
  };

  return (
    <div className={s["page-camera"]}>
      <div className={s["container"]}>
        <div className={s["btn-close"]}>
          <Link to="/individual"><img src={iconClose} alt="" /></Link>
        </div>

        <video
          id="camera-stream"
          className={s["stream"]}
          width={videoSize.x}
          height={videoSize.y}
          ref={videoRef}
          autoPlay={true}
          playsInline={true}
          muted={true}>
        </video>

        <canvas
          className={s["c"]}
          style={{ width: `${videoSize.x}px`, height: `${videoSize.y}px` }}
          width={videoSize.x}
          height={videoSize.y}
          ref={canvasRef}>
        </canvas>

        <motion.div
          className={s["overlay-success"]}
          initial={{ opacity: 0 }}
          animate={ isModalVisible ? { opacity: 1 } : {}}
          transition={{ ease: "linear", duration: 0.5 }}>
          <div className={s["content"]}>
            <div className={s["image"]}><img src={iconStone} alt="" /></div>
            <div className={s["text"]}><p>この端末で体験可能です。</p></div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
