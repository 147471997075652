import { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import * as tf from '@tensorflow/tfjs';
import { loadGraphModel } from '@tensorflow/tfjs-converter';
import { AnimatePresence, motion, useMotionValue, useSpring } from 'framer-motion';

import AnimalDetailModal from '../components/AnimalDetailModal';

import s from '../assets/scss/pages/PromotionPage.module.scss';

import imgFrame from '../assets/images/frame-white.png';
import iconCamera from '../assets/images/icon-camera.png';
import iconClose from '../assets/images/icon-close.svg';
import iconStone from '../assets/images/icon-record-stone.png';
import iconStone1 from '../assets/images/icon-record-stone-1.png';
import iconStone2 from '../assets/images/icon-record-stone-2.png';
import iconStone3 from '../assets/images/icon-record-stone-3.png';
import iconStone4 from '../assets/images/icon-record-stone-4.png';

import animalFowl from '../assets/images/animal-fowl.png';
import animalCoati from '../assets/images/animal-coati.png';
import animalMargay from '../assets/images/animal-margay.png';
import animalBear from '../assets/images/animal-bear.png';
import animalTamandua from '../assets/images/animal-tamandua.png';
import animalArapaima from '../assets/images/animal-arapaima.png';
import animalGlizard from '../assets/images/animal-glizard.png';
import animalSquid from '../assets/images/animal-squid.png';
import animalWhale from '../assets/images/animal-whale.png';
import animalPanda from '../assets/images/animal-panda.png';


const threshold = 0.65;

async function loadModel(callback) {
  try {
    let model;
    const serverModelUrl = `${process.env.PUBLIC_URL}/models/model-promo/model.json`;

    model = await loadGraphModel(serverModelUrl);
    return model;
  } catch (error) {
    console.error('An error occurred while loading the model:', error);
    throw error;
  }
}

const classesDir = {
  1: { name: 'marker_hatena', id: 1, },
  2: { name: 'siamang', id: 2, },
  3: { name: 'fowl', id: 3, },
  4: { name: 'coati', id: 4, },
  5: { name: 'margay', id: 5, },
  6: { name: 'moose', id: 6, },
  7: { name: 'bear', id: 7, },
  8: { name: 'bowl', id: 8, },
  9: { name: 'tamandua', id: 9, },
  10: { name: 'panda', id: 10, },
  11: { name: 'jewel', id: 11, },
  12: { name: 'catfish', id: 12, },
  13: { name: 'trout', id: 13, },
  14: { name: 'mole', id: 14, },
  15: { name: 'dolphin', id: 15, },
  16: { name: 'arapaima', id: 16, },
  17: { name: 'oarfish', id: 17, },
  18: { name: 'squid', id: 18, },
  19: { name: 'fshark', id: 19, },
  20: { name: 'whale', id: 20, },
  21: { name: 'glizard', id: 21, },
  22: { name: 'shrew', id: 22, },
  23: { name: 'clizard', id: 23, },
  24: { name: 'snake', id: 24, },
  25: { name: 'gnu', id: 25, },
  26: { name: 'hyena', id: 26, },
  27: { name: 'lionm', id: 27, },
  28: { name: 'elephantf', id: 28, },
  29: { name: 'poster_kv', id: 29 }
}

const animalData = [
  { imageUrl: animalFowl, desc: 'ずかんミュージアムにいがたで\nもりを みおろす そらの\nいきものを はっけん。\n\nにいがた けんみんかいかんで\nひくく うつくしいこえで\nないている ところを\nきろく してみよう。' },
  { imageUrl: animalCoati, desc: 'ずかんミュージアムにいがたで\nきような もりのいきものを\nはっけん。\n\nにいがた けんみんかいかんで\nりょうてを\nうごかして からだを\nキレイにしている すがたを\nきろく してみよう。' },
  { imageUrl: animalMargay, desc: 'ずかんミュージアムにいがたで\nはんてんもようの\nいきものを はっけん。\n\nにいがた けんみんかいかんで\nきのうえから さかさまに\nなって えものを\nつかまえるすがたを\nきろく してみよう。' },
  { imageUrl: animalBear, desc: 'ずかんミュージアムにいがたで\nおおきな けむくじゃらの\nいきものを はっけん。\n\nにいがた けんみんかいかんで\nきに せなかを こする\nしぐさを\nきろく してみよう。' },
  { imageUrl: animalTamandua, desc: 'ずかんミュージアムにいがたで\nかおがながい いきものを\nはっけん。\n\nにいがた けんみんかいかんで\nするどいツメに\nようじんして、したを\nのばして たべるすがたを\nきろく してみよう。' },
  { imageUrl: animalArapaima, desc: 'ずかんミュージアムにいがたで\nかわに すむ おおきな\nいきものをはっけん。\n\nにいがた けんみんかいかんで\nえものを たべるすがたを\nみのがさないように\nきろく してみよう。' },
  { imageUrl: animalGlizard, desc: 'ずかんミュージアムにいがたで\nとげとげした\nいきものを はっけん。\n\nにいがた けんみんかいかんで\nよくかんさつし\nまるまった すがたを\nきろく してみよう。' },
  { imageUrl: animalSquid, desc: 'ずかんミュージアムにいがたで\nおおきな め をもつ\nいきものを はっけん。\n\nにいがた けんみんかいかんで\nふかいうみでおよぐ すがたを\nじっくりかんさつし\nきろく してみよう。' },
  { imageUrl: animalWhale, desc: 'ずかんミュージアムにいがたで\nくろくて おおきな あたまを \nもつ いきものを はっけん。\n\nにいがた けんみんかいかんで\nふかいうみにひびきわたる\nなきごえをかんさつし\nきろく してみよう。' },
  { imageUrl: animalPanda, desc: 'ずかんミュージアムにいがたで\nしろくろ もようの いきものを\nはっけん。\n\nにいがた けんみんかいかんで\nなるべく\nちかづいて かんさつし、\nしょくじの すがた を\nきろく してみよう。' }
]

export default function PromotionPage() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [videoSize, setVideoSize] = useState({ x: 390, y: 844 });
  const [isLoadOver, setIsLoadOver] = useState(false);
  const [isPosterDetected, setIsPosterDetected] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState(animalData[0]);

  const loadingProgress = useMotionValue(0);
  const scaleX = useSpring(loadingProgress, {
    stiffness: 300,
    damping: 60,
  });

  useEffect(() => {
    if (window.innerWidth >= 1024 && window.screen.orientation.type === "landscape-primary") {
      setTimeout(() => {
        window.location.href = "https://025.teny.co.jp/event/zukan";
      }, 4000);
    }
  }, []);

  useEffect(() => {
    let currentStream = null;
    let animLoop = null;
    let model;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const modelPromise = loadModel();

      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: { facingMode: "environment" }
        })
        .then(stream => {
          currentStream = stream;
          window.stream = stream;
          videoRef.current.srcObject = stream;
          loadingProgress.set(0.15);

          return new Promise((resolve, reject) => {
            videoRef.current.onloadedmetadata = () => {
              console.log('1. Camera stream Loaded');
              loadingProgress.set(0.33);
              resolve();
            };
          });
        });

      Promise.all([modelPromise, webCamPromise])
        .then(values => {
          model = values[0];
          loadingProgress.set(0.67);

          tf.env().set("WEBGL_DELETE_TEXTURE_THRESHOLD", 512000000);
          detectFrame();

          console.log('2. Model Loaded')
        })
        .catch(error => {
          console.error(error);
        });
    }

    function detectFrame() {
      if (!videoRef.current || !model) return;
      tf.engine().startScope();
      model.executeAsync(processInput(videoRef.current)).then(predictions => {
        renderPredictions(predictions, videoRef.current);

        animLoop = setTimeout(() => detectFrame(), 1000);

        loadingProgress.set(1);
        setIsLoadOver(true);
        console.log('3. Animation loop running...');

        tf.engine().endScope();
      });
    };

    return () => {
      clearTimeout(animLoop);

      if (currentStream) {
        const tracks = currentStream.getTracks();
        tracks.forEach(track => track.stop());
        currentStream = null;
        window.stream = null;
        // videoRef.current.srcObject = null;
      }
    };
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setVideoSize({ x: parseInt(width), y: parseInt(height) });
      }
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function processInput(video_frame) {
    const tfimg = tf.browser.fromPixels(video_frame).toInt();
    const expandedimg = tfimg.transpose([0, 1, 2]).expandDims();
    return expandedimg;
  };

  function buildDetectedObjects(scores, boxes, classes, classesDir) {
    const detectedObjects = []
    // var videoFrame = document.getElementById('frame');
    const videoFrame = canvasRef.current;

    const th = threshold;
    scores[0].forEach((score, i) => {
      if (classes && classes[i] && score > th) {
        const bbox = [];
        const minY = boxes[0][i][0] * videoFrame.offsetHeight;
        const minX = boxes[0][i][1] * videoFrame.offsetWidth;
        const maxY = boxes[0][i][2] * videoFrame.offsetHeight;
        const maxX = boxes[0][i][3] * videoFrame.offsetWidth;
        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;


        detectedObjects.push({
          class: Math.round(classes[i]),
          label: classesDir[Math.round(classes[i])].name,
          score: score.toFixed(4),
          bbox: bbox
        });
      }
    })

    return detectedObjects;
  }

  function renderPredictions(predictions) {
    if (!canvasRef.current) return;
    // const ctx = canvasRef.current.getContext("2d");
    // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    let boxes, scores, classes;
    predictions.forEach((prediction, i) => {  // HACK: 毎フレームやらなくても初期化時に1度でよい
      const arr = prediction.arraySync();
      const pr = arr[0];

      if (Array.isArray(pr) && pr.length === 100) {
        if (Array.isArray(pr[0])) {
          if (pr[0].length === 4) { // WARNING: クラス数が4の時、誤って他のインデックスを指定する可能性がある
            boxes = prediction.arraySync();
          }
        } else if (Number.isInteger(pr[0])) {
          let isClass = true;
          for (let j = 0; j < pr.length; j++) {
            if (pr[j] > Object.keys(classesDir).length) {
              isClass = false;
              break;
            }
          }
          if (isClass) {
            classes = prediction.dataSync();
          }
        } else {
          scores = prediction.arraySync();
        }
      }
    })

    const detections = buildDetectedObjects(scores, boxes, classes, classesDir);
    const detectedPoster = detections && detections.find(item => item.label === 'poster_kv');

    if (detectedPoster) {
      setIsPosterDetected(true);
    }
    else {
      setIsPosterDetected(false);
    }
  };

  const handleCameraClick = e => {
    e.preventDefault();
    const randomIndex = Math.floor(Math.random() * animalData.length);
    setCurrentData(animalData[randomIndex]);
    setIsModalVisible(true);
  }

  return (
    <div className={s["page-camera"]}>
      <div className={s["container"]}>
        <div className={s["btn-close"]}>
          <Link to="/" reloadDocument><img src={iconClose} alt="" /></Link>
        </div>

        <video
          id="camera-stream"
          className={s["stream"]}
          style={{ opacity: isLoadOver ? 1: 0 }}
          width={videoSize.x}
          height={videoSize.y}
          ref={videoRef}
          autoPlay={true}
          playsInline={true}
          muted={true}>
        </video>

        <canvas
          className={s["c"]}
          style={{ width: `${videoSize.x}px`, height: `${videoSize.y}px` }}
          width={videoSize.x}
          height={videoSize.y}
          ref={canvasRef}>
        </canvas>

        <div className={`${s["btn-camera"]} ${isPosterDetected ? s["is-visible"] : ''}`}>
          <motion.div whileTap={{ scale: 1.15 }}>
            <a href="#" onClick={e => handleCameraClick(e)}>
              <img src={iconCamera} alt="記録する" />
            </a>
          </motion.div>
        </div>

        {isLoadOver && (
          <>
            <div
              className={`${s["frame"]} ${isPosterDetected ? s["is-fixed"] : ''}`}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1, ease: "linear" }}>
                <img src={imgFrame} alt="" />
              </motion.div>
            </div>

            <motion.div
              className={s["guide"]}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1, ease: "linear" }}>
              <AnimatePresence mode="wait">
                {isPosterDetected ? (
                  <motion.div key={3} className={`${s["guide__item"]}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, ease: "linear" }}>
                    <p>きろくのチャンス！</p>
                  </motion.div>
                ) : (
                  <motion.div key={1} className={`${s["guide__item"]} ${s["guide__item--first"]}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, ease: "linear" }}>
                    <p>ポスターをさがして<br />カメラをむけてみよう</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </>
        )}

        <AnimatePresence>
          {!isLoadOver && (
            <motion.div
              className={s["loading-overlay"]}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "linear", duration: 0.5 }}>
              <div className={s["icon"]}>
                <img src={iconStone1} alt="" />
                <img src={iconStone2} alt="" />
                <img src={iconStone3} alt="" />
                <img src={iconStone4} alt="" />
              </div>
              <div className={s["text"]}><p>カメラ起動中</p></div>
              <div className={s["progress"]}>
                <motion.span
                  initial={{ scaleX: 0 }}
                  style={{ scaleX, transformOrigin: "left" }}>
                </motion.span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {isModalVisible && (
          <AnimalDetailModal
            imageUrl={currentData.imageUrl}
            desc={currentData.desc}
            onClose={() => setIsModalVisible(false)} />
        )}
      </AnimatePresence>

      <motion.div
        className={`${s["warning-overlay"]} ${s["show-pc"]}`}>
        <div className={s["image"]}><img src={iconStone} alt="" /></div>
        <div className={s["text"]}>
          <p>このページはスマートフォン専用です。</p>
        </div>
      </motion.div>
    </div>
  )
}
