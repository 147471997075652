import { Link } from "react-router-dom"

import logo from '../assets/images/logo.svg';
import qrCodeImg from '../assets/images/qr_individual.png';
import stepImgPC from '../assets/images/individual_img_pc.png';
import stepImgSP from '../assets/images/individual_img_sp.png';

import s from '../assets/scss/pages/SubPage.module.scss';

export default function IndividualPage() {
  return (
    <div className={s["page-individual"]}>
      <div className={s["logo"]}>
        <div className="container">
          <Link to="/"><img src={logo} alt="Zukan Museum Niigata" /></Link>
        </div>
      </div>

      <div className={s["intro"]}>
        <div className="container">
          <p className={s["intro__lead"]}>
            あなたのスマートフォンで、ずかんミュージアムのいきものを<br />
            正しく記録できるか確認できます。
          </p>

          <div className={s["block"]}>
            <p className={s["intro__title"]}>＜用意するもの＞</p>
            <p className={s["intro__text"]}>スマートフォン(タブレット)を1台</p>
          </div>
        </div>
      </div>

      <div className={s["main"]}>
        <div className={`container ${s["main__container"]}`}>
          <div className={s["block"]}>
            <div className={s["block__head"]}><p>確認の手順</p></div>
            <div className={s["block__main"]}>
              <div className={s["steps"]}>
                <div className={s["steps__item"]}>
                  <p className={s["steps__title"]}><span>1</span>カメラ機能が起動するか確認！</p>
                  <p className={s["steps__text"]}>体験で使用予定のスマートフォン(タブレット)で確認してください。</p>
                  <div className={s["steps__qr"]}>
                    <figure><img src={qrCodeImg} alt="" /></figure>
                    <span>
                      こちらの2次元コードを<br />
                      スマートフォンのカメラで<br />
                      読み込んでください
                    </span>
                  </div>

                  <div className={s["steps__btnwrap"]}>
                    <div className={s["steps__btn"]}>
                      <Link to="/individual/camera" target="_blank"><span>クリックして、カメラ機能を確認</span></Link>
                    </div>
                    <p className={s["steps__text"]}>
                      こちらのボタンをクリックして<br />
                      カメラを起動してみてください
                    </p>
                  </div>
                </div>

                <div className={s["steps__item"]}>
                  <p className={s["steps__title"]}><span>2</span>このように表示されると体験可能な端末です。</p>
                  <div className={s["steps__image"]}>
                    <img className="sp-hide" src={stepImgPC} alt="このように表示されると体験可能な端末です。" />
                    <img className="sp-show" src={stepImgSP} alt="このように表示されると体験可能な端末です。" />
                  </div>
                </div>
              </div>

              <div className={s["block__notice"]}>
                <small>
                  ※カメラが起動しない場合や、起動に時間がかかる場合は、レンタル機のご予約をご検討ください。<br />
                  TeNYチケット専用ダイヤル025-281-8000(平日10:00-17:30)<br />
                  会場で空きがある場合はその場でのレンタルも可能です。<br />
                  ※より詳細な動作チェックを行いたい場合は、団体で来訪予定のお客様向けの確認ページにてご確認が可能です。
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}