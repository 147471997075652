import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import IndexPage from "./pages/IndexPage";
import IndividualPage from "./pages/IndividualPage";
import IndividualCameraPage from './pages/IndividualCameraPage';
import GroupPage from "./pages/GroupPage";
import GroupCameraPage from './pages/GroupCameraPage';
import PromotionPage from './pages/PromotionPage';


export default function App() {
  const location = useLocation();

  return (
    <div className="app">
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<IndexPage></IndexPage>}></Route>
        <Route path="/individual" element={<IndividualPage></IndividualPage>}></Route>
        <Route path="/individual/camera" element={<IndividualCameraPage></IndividualCameraPage>}></Route>
        <Route path="/group" element={<GroupPage></GroupPage>}></Route>
        <Route path="/group/camera" element={<GroupCameraPage></GroupCameraPage>}></Route>
        <Route path="/promotion" element={<PromotionPage></PromotionPage>}></Route>
      </Routes>
    </div>
  )
}