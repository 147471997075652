import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.svg';
import bgButton from '../assets/images/bg-btn.png';

import s from '../assets/scss/pages/IndexPage.module.scss';

export default function IndexPage() {
  return (
    <div className={s["page-index"]}>
      <div className="container">
        <div className={s["logo"]}><img src={logo} alt="Zukan Museum Niigata" /></div>

        <div className={s["btnwrap"]}>
          <div className={s["btn"]}>
            <Link to="/individual"><span>個人やご家族で<br />来訪予定のお客様はこちら</span></Link>
          </div>

          <div className={s["btn-group"]}>
            <Link to="/group"><span>団体で来訪予定のお客様はこちら</span></Link>
          </div>

          {/* <div className={`${s["btn"]} ${s["btn-promo"]}`}>
            <Link reloadDocument to="/promotion" style={{ backgroundImage: `url(${bgButton})` }}>
              <span>ポスターを見つけたら<br />カメラで読み込んでみよう</span>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  )
}